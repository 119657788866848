import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon, useIonRouter, IonButtons } from '@ionic/react';
import { Capacitor, Plugins } from '@capacitor/core';
import './Home.css';

const { App, Share, StatusBar } = Plugins;

const Home: React.FC = () => {
  const shareRet = async () => {
    await Share.share({
      title: 'Matanamadh LIVE Darshan',
      text: 'I am taking LIVE Matanamadh Darshan on Mobile Application, download now at ',
      url: 'https://matanamadh.org/download-android.php',
      dialogTitle: 'Matanamadh LIVE Darshan'
    });
  }

  const __infoBlock = (<IonGrid className="flex-col space-around h-half">
    <IonRow>
      <IonCol className="ion-text-center ion-text-strong">
        Darshan And Aarti Timings
</IonCol>
    </IonRow>
    <IonRow>
      <IonCol className="red-text ion-text-center">
        Mangla Darshan:<br /> 05:00 AM<br />
  Dhoop Aarti:<br /> 09:00 AM<br />
  Sandhya Aarti:<br /> At Sunset
</IonCol>
      <IonCol className="red-text ion-text-center">
        મંગળા દર્શન:<br /> સવારે 05:00 વાગ્યે<br />
  ધૂપ આરતી:<br /> સવારે 09:00 વાગ્યે<br />
  સંધ્યા આરતી:<br /> સાંજે સૂર્યાસ્ત સમયે<br />
      </IonCol>
    </IonRow>
    <IonRow className="ion-text-center">
      <IonCol>
        <IonIcon color="secondary" md="share-social" size="large" onClick={shareRet}></IonIcon>
      </IonCol>
    </IonRow>
    <IonRow className="ion-text-center">
      <IonCol>
        For more details visit us on: <a style={{ "color": "black" }} href="https://matanamadh.org">matanamadh.org</a><br />
  Copyright &copy; 2020. Matanamadh Jagir Trust. <br />All Rights Reserved.
</IonCol>
    </IonRow>
  </IonGrid>)

  const [orient, setOrient] = useState(window.screen.orientation.type);
  const [playerHeight, setPlayerHeight] = useState("100%");
  const [infoBlock, setInfoBlock] = useState(__infoBlock);

  let closeApp = () => {
    if (window.confirm("You want to exit the app?")) {
      if (Capacitor.isNative) {
        App.exitApp();
      }
      else {
        window.close();
      }
    }
  }

  const url = "https://www.freedocast.com/embed.html/embedPlay/events/9606";

  const reloadApp = () => {
    window.location.reload();
  }

  const checkPlayerAlignment = () => {
    // alert(window.screen.orientation.type)
    setOrient(window.screen.orientation.type)
    if (window.screen.orientation.type === 'landscape-primary') {
      setInfoBlock(null)
      setPlayerHeight("100%")
    }
    else {
      setInfoBlock(__infoBlock)
      setPlayerHeight("80%")
    }
  }

  const ionRouter = useIonRouter();
  useEffect(() => {
    StatusBar.setBackgroundColor({ color: '#FFBB00' });

    // checkPlayerAlignment()

    window.screen.orientation?.addEventListener('change', function (e) {
      checkPlayerAlignment();
    })

    document.addEventListener('ionBackButton', (ev: any) => {
      ev.detail.register(-1, () => {
        if (!ionRouter.canGoBack()) {
          closeApp();
        }
      });
    });
  })

  return (
    <IonPage>
      <IonHeader style={{ "background-color": "#ffca22" }}>
        {orient == 'portrait-primary' ? 
          <IonToolbar>
            <IonButtons slot="start">
              {/* stroke is hard coded in the .svg file */}
              <IonIcon style={{ "padding": "10px" }} color="success" size="large" name="refresh"
                onClick={reloadApp}
              ></IonIcon>
            </IonButtons>
            <IonTitle className="heading">
              <img src="/assets/logo.png" height="45px" alt="Matanamadh" />
            </IonTitle>
            <IonButtons slot="end">
              <IonIcon style={{ "padding": "10px" }} color="secondary" md="close" size="large"
                onClick={closeApp}
              ></IonIcon>
            </IonButtons>
          </IonToolbar>
        : null}
      </IonHeader>
      <IonContent fullscreen>
        <div style={{ "height": (orient === "portrait-primary") ? "50%" : "100%" }}>
          <iframe frameBorder={0} title="live" id="live" src={url} width="100%" height={playerHeight} allowFullScreen={true} allowTransparency={true} scrolling="noscreen"></iframe>
        </div>
        {infoBlock}
      </IonContent>
    </IonPage>
  );
};

export default Home;
